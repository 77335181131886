// export const quizQuestions = [
//   {
//     id: 1,
//     question:
//       'Analogy Completion: Complete the analogy with the most appropriate pair of words. Example:Cat is to Kitten as Dog is to _____',
//     answers: [
//       {
//         id: 1,
//         ans: 'Puppy',
//         isCorrect: true,
//       },
//       {
//         id: 2,
//         ans: 'Kitten',
//         isCorrect: false,
//       },
//       {
//         id: 3,
//         ans: 'Cub',
//         isCorrect: false,
//       },
//       {
//         id: 4,
//         ans: 'Calf',
//         isCorrect: false,
//       },
//     ],
//   },
//   {
//     id: 2,
//     question:
//       'Comparisons: Choose the correct word to complete the comparison. Example:Of the two, John is the _______.',
//     answers: [
//       {
//         id: 1,
//         ans: 'tallest',
//         isCorrect: false,
//       },
//       {
//         id: 2,
//         ans: 'more taller',
//         isCorrect: true,
//       },
//       {
//         id: 3,
//         ans: 'tall',
//         isCorrect: false,
//       },
//       {
//         id: 4,
//         ans: 'taller',
//         isCorrect: true,
//       },
//     ],
//   },
//   {
//     id: 3,
//     question:
//       'Common Errors: Identify the common error in the given sentence. Example: She arrived at the station and waiting for the train.',
//     answers: [
//       {
//         id: 1,
//         ans: 'arrived',
//         isCorrect: false,
//       },
//       {
//         id: 2,
//         ans: 'waiting',
//         isCorrect: false,
//       },
//       {
//         id: 3,
//         ans: 'and',
//         isCorrect: true,
//       },
//       {
//         id: 4,
//         ans: 'No error',
//         isCorrect: false,
//       },
//     ],
//   },
//   {
//     id: 4,
//     question:
//       'Ambiguity: Identify the part of the sentence that creates ambiguity.Sentence: I saw a man with a telescope.',
//     answers: [
//       {
//         id: 1,
//         ans: ' I saw',
//         isCorrect: false,
//       },
//       {
//         id: 2,
//         ans: 'a man',
//         isCorrect: false,
//       },
//       {
//         id: 3,
//         ans: 'with a telescope',
//         isCorrect: true,
//       },
//       {
//         id: 4,
//         ans: 'No ambiguity',
//         isCorrect: false,
//       },
//     ],
//   },
//   {
//     id: 5,
//     question:
//       "Punctuation: Choose the option with the correct punctuation. Sentence: I can't believe it she exclaimed.",
//     answers: [
//       {
//         id: 1,
//         ans: " I can't believe it, she exclaimed.",
//         isCorrect: true,
//       },
//       {
//         id: 2,
//         ans: "I can't believe it; she exclaimed.",
//         isCorrect: false,
//       },
//       {
//         id: 3,
//         ans: " I can't believe it. She exclaimed.",
//         isCorrect: false,
//       },
//       {
//         id: 4,
//         ans: " I can't believe it, she exclaimed!",
//         isCorrect: false,
//       },
//     ],
//   },
//   {
//     id: 6,
//     question:
//       'Homophones: Choose the word that sounds the same but has a different meaning.',
//     answers: [
//       {
//         id: 1,
//         ans: 'Meat',
//         isCorrect: false,
//       },
//       {
//         id: 2,
//         ans: 'Meet',
//         isCorrect: true,
//       },
//       {
//         id: 3,
//         ans: 'Mete',
//         isCorrect: false,
//       },
//       {
//         id: 4,
//         ans: 'All of the above',
//         isCorrect: false,
//       },
//     ],
//   },
//   {
//     id: 7,
//     question:
//       'Synonym: Choose the word that is most similar in meaning to the given word.Word: Examine',
//     answers: [
//       {
//         id: 1,
//         ans: 'Inspect',
//         isCorrect: true,
//       },
//       {
//         id: 2,
//         ans: 'Ignore',
//         isCorrect: false,
//       },
//       {
//         id: 3,
//         ans: 'Halt',
//         isCorrect: false,
//       },
//       {
//         id: 4,
//         ans: 'Combine',
//         isCorrect: false,
//       },
//     ],
//   },
//   {
//     id: 8,
//     question:
//       'Antonym: Choose the word that is most opposite in meaning to the given word.Word: Ancient',
//     answers: [
//       {
//         id: 1,
//         ans: 'Old',
//         isCorrect: false,
//       },
//       {
//         id: 2,
//         ans: 'Modern',
//         isCorrect: true,
//       },
//       {
//         id: 3,
//         ans: 'Traditional',
//         isCorrect: false,
//       },
//       {
//         id: 4,
//         ans: 'Historical',
//         isCorrect: false,
//       },
//     ],
//   },
//   {
//     id: 9,
//     question:
//       'Analogies: Complete the analogy with the most appropriate pair of words. Example:Apple is to Fruit as Potato is to ____',
//     answers: [
//       {
//         id: 1,
//         ans: 'Vegetable',
//         isCorrect: true,
//       },
//       {
//         id: 2,
//         ans: 'Root',
//         isCorrect: false,
//       },
//       {
//         id: 3,
//         ans: 'Plant',
//         isCorrect: false,
//       },
//       {
//         id: 4,
//         ans: 'Carbohydrate',
//         isCorrect: false,
//       },
//     ],
//   },
//   {
//     id: 10,
//     question:
//       'Arithmetic Aptitude: If a car travels at a speed of 60 km/hour, how far will it travel in 5 hours?',
//     answers: [
//       {
//         id: 1,
//         ans: '300 km',
//         isCorrect: true,
//       },
//       {
//         id: 2,
//         ans: '250 km',
//         isCorrect: false,
//       },
//       {
//         id: 3,
//         ans: '350 km',
//         isCorrect: false,
//       },
//       {
//         id: 4,
//         ans: '400 km',
//         isCorrect: false,
//       },
//     ],
//   },

//   {
//     id: 11,
//     question: 'OOP: What is encapsulation in object-oriented programming?',
//     answers: [
//       {
//         id: 1,
//         ans: 'It is the ability of an object to take on many forms.',
//         isCorrect: false,
//       },
//       {
//         id: 2,
//         ans: 'It is the process of combining data and functions into a single unit.',
//         isCorrect: false,
//       },
//       {
//         id: 3,
//         ans: 'It is the mechanism of hiding the internal implementation details of an object and only exposing the necessary features.',
//         isCorrect: true,
//       },
//       {
//         id: 4,
//         ans: 'It is the ability to define a new class that inherits from an existing class.',
//         isCorrect: false,
//       },
//     ],
//   },

//   {
//     id: 12,
//     question: 'OOP: What is inheritance in object-oriented programming?',
//     answers: [
//       {
//         id: 1,
//         ans: 'It is the ability of an object to take on many forms.',
//         isCorrect: false,
//       },
//       {
//         id: 2,
//         ans: 'It is the process of combining data and functions into a single unit.',
//         isCorrect: false,
//       },
//       {
//         id: 3,
//         ans: 'It is the mechanism of hiding the internal implementation details of an object and only exposing the necessary features.',
//         isCorrect: false,
//       },
//       {
//         id: 4,
//         ans: 'It is the ability to define a new class that inherits from an existing class.',
//         isCorrect: true,
//       },
//     ],
//   },
//   {
//     id: 13,
//     question: 'OOP: What is polymorphism in object-oriented programming?',
//     answers: [
//       {
//         id: 1,
//         ans: 'It is the ability of an object to take on many forms.',
//         isCorrect: true,
//       },
//       {
//         id: 2,
//         ans: 'It is the process of combining data and functions into a single unit.',
//         isCorrect: false,
//       },
//       {
//         id: 3,
//         ans: 'It is the mechanism of hiding the internal implementation details of an object and only exposing the necessary features.',
//         isCorrect: false,
//       },
//       {
//         id: 4,
//         ans: 'It is the ability to define a new class that inherits from an existing class.',
//         isCorrect: false,
//       },
//     ],
//   },
//   {
//     id: 14,
//     question: 'OOP: What is a constructor in object-oriented programming?',
//     answers: [
//       {
//         id: 1,
//         ans: 'It is a special method that is automatically called when an object is created.',
//         isCorrect: true,
//       },
//       {
//         id: 2,
//         ans: 'It is a method used to destroy objects.',
//         isCorrect: false,
//       },
//       {
//         id: 3,
//         ans: "It is a method used to copy the values of one object's attributes to another object.",
//         isCorrect: false,
//       },
//       {
//         id: 4,
//         ans: 'It is a method used to perform a specific task on an object.',
//         isCorrect: false,
//       },
//     ],
//   },
//   {
//     id: 15,
//     question: 'OOP: What is method overloading in object-oriented programming?',
//     answers: [
//       {
//         id: 1,
//         ans: 'It is a feature that allows a subclass to provide a specific implementation of a method that is already defined in its superclass.',
//         isCorrect: false,
//       },
//       {
//         id: 2,
//         ans: 'It is the ability to define multiple methods with the same name but different parameters in the same class.',
//         isCorrect: true,
//       },
//       {
//         id: 3,
//         ans: 'It is the ability to define a new class that inherits from an existing class.',
//         isCorrect: false,
//       },
//       {
//         id: 4,
//         ans: 'It is the ability of an object to take on many forms.',
//         isCorrect: false,
//       },
//     ],
//   },
//   {
//     id: 16,
//     question: 'OOP: What is method overriding in object-oriented programming?',
//     answers: [
//       {
//         id: 1,
//         ans: 'It is a feature that allows a subclass to provide a specific implementation of a method that is already defined in its superclass.',
//         isCorrect: true,
//       },
//       {
//         id: 2,
//         ans: 'It is the ability to define multiple methods with the same name but different parameters in the same class.',
//         isCorrect: false,
//       },
//       {
//         id: 3,
//         ans: 'It is the ability to define a new class that inherits from an existing class.',
//         isCorrect: false,
//       },
//       {
//         id: 4,
//         ans: 'It is the ability of an object to take on many forms.',
//         isCorrect: false,
//       },
//     ],
//   },
//   {
//     id: 17,
//     question: 'OOP: What is a destructor in object-oriented programming?',
//     answers: [
//       {
//         id: 1,
//         ans: 'It is a special method that is automatically called when an object is created.',
//         isCorrect: false,
//       },
//       {
//         id: 2,
//         ans: 'It is a method used to destroy objects.',
//         isCorrect: true,
//       },
//       {
//         id: 3,
//         ans: "It is a method used to copy the values of one object's attributes to another object.",
//         isCorrect: false,
//       },
//       {
//         id: 4,
//         ans: 'It is a method used to perform a specific task on an object.',
//         isCorrect: false,
//       },
//     ],
//   },
//   {
//     id: 18,
//     question: 'OOP: What is composition in object-oriented programming?',
//     answers: [
//       {
//         id: 1,
//         ans: "It is a design technique that models a 'has-a' relationship between classes, where one class contains an object of another class as a member.",
//         isCorrect: true,
//       },
//       {
//         id: 2,
//         ans: 'It is a technique of defining multiple methods with the same name but different parameters in the same class.',
//         isCorrect: false,
//       },
//       {
//         id: 3,
//         ans: 'It is a mechanism of hiding the internal implementation details of an object and only exposing the necessary features.',
//         isCorrect: false,
//       },
//       {
//         id: 4,
//         ans: 'It is the ability to define a new class that inherits from an existing class.',
//         isCorrect: false,
//       },
//     ],
//   },
//   {
//     id: 19,
//     question: 'OOP: What is aggregation in object-oriented programming?',
//     answers: [
//       {
//         id: 1,
//         ans: "It is a design technique that models a 'has-a' relationship between classes, where one class contains an object of another class as a member.",
//         isCorrect: false,
//       },
//       {
//         id: 2,
//         ans: 'It is a technique of defining multiple methods with the same name but different parameters in the same class.',
//         isCorrect: false,
//       },
//       {
//         id: 3,
//         ans: 'It is a mechanism of hiding the internal implementation details of an object and only exposing the necessary features.',
//         isCorrect: false,
//       },
//       {
//         id: 4,
//         ans: "It is a design technique that models a 'has-a' relationship between classes, where one class has a reference to another class.",
//         isCorrect: true,
//       },
//     ],
//   },
//   {
//     id: 20,
//     question:
//       'OOP: What is the difference between composition and aggregation in object-oriented programming?',
//     answers: [
//       {
//         id: 1,
//         ans: "There is no difference, both represent a 'has-a' relationship between classes.",
//         isCorrect: false,
//       },
//       {
//         id: 2,
//         ans: 'Composition is a stronger form of association where the child object cannot exist without the parent object, whereas aggregation is a weaker form where the child object can exist independently.',
//         isCorrect: true,
//       },
//       {
//         id: 3,
//         ans: 'Aggregation is a stronger form of association where the child object cannot exist without the parent object, whereas composition is a weaker form where the child object can exist independently.',
//         isCorrect: false,
//       },
//       {
//         id: 4,
//         ans: 'Composition is used to define multiple methods with the same name but different parameters in the same class, whereas aggregation is used to model inheritance.',
//         isCorrect: false,
//       },
//     ],
//   },
//   {
//     id: 21,
//     question: 'When an AI helps you write an email, what type of AI is it?',
//     answers: [
//       {
//         id: 1,
//         ans: 'Robotics AI',
//         isCorrect: false,
//       },
//       {
//         id: 2,
//         ans: 'Natural Language Processing (NLP) AI',
//         isCorrect: true,
//       },
//       {
//         id: 3,
//         ans: 'Computer Vision AI',
//         isCorrect: false,
//       },
//       {
//         id: 4,
//         ans: 'Self-Driving Car AI',
//         isCorrect: false,
//       },
//     ],
//   },
//   {
//     id: 22,
//     question: 'What is the goal of Explainable AI (XAI)?',
//     answers: [
//       {
//         id: 1,
//         ans: 'Make AI run faster on computers',
//         isCorrect: false,
//       },
//       {
//         id: 2,
//         ans: 'Help us understand how AI reaches its decisions',
//         isCorrect: true,
//       },
//       {
//         id: 3,
//         ans: 'Improve the artistic abilities of AI',
//         isCorrect: false,
//       },
//       {
//         id: 4,
//         ans: 'Train AI models on less data',
//         isCorrect: false,
//       },
//     ],
//   },
//   {
//     id: 23,
//     question: 'What is a potential benefit of AI in healthcare?',
//     answers: [
//       {
//         id: 1,
//         ans: 'Diagnosing diseases more accurately',
//         isCorrect: false,
//       },
//       {
//         id: 2,
//         ans: 'Developing new types of medication',
//         isCorrect: false,
//       },
//       {
//         id: 3,
//         ans: 'Performing complex surgeries',
//         isCorrect: false,
//       },
//       {
//         id: 4,
//         ans: 'All of the above',
//         isCorrect: true,
//       },
//     ],
//   },
//   {
//     id: 24,
//     question: 'I am a large language model. What kind of AI am I considered?',
//     answers: [
//       {
//         id: 1,
//         ans: 'Narrow AI',
//         isCorrect: true,
//       },
//       {
//         id: 2,
//         ans: 'Artificial General Intelligence',
//         isCorrect: false,
//       },
//       {
//         id: 3,
//         ans: 'Superintelligence',
//         isCorrect: false,
//       },
//       {
//         id: 4,
//         ans: 'Machine Learning',
//         isCorrect: false,
//       },
//     ],
//   },
//   {
//     id: 25,
//     question: 'What is Devin known for?',
//     answers: [
//       {
//         id: 1,
//         ans: 'A new type of self-driving car',
//         isCorrect: false,
//       },
//       {
//         id: 2,
//         ans: "The world's first AI financial analyst",
//         isCorrect: false,
//       },
//       {
//         id: 3,
//         ans: "The world's first AI software engineer",
//         isCorrect: true,
//       },
//       {
//         id: 4,
//         ans: 'A social media platform powered by AI',
//         isCorrect: false,
//       },
//     ],
//   },
//   {
//     id: 26,
//     question:
//       'According to information about Devin, what is its role in software development?',
//     answers: [
//       {
//         id: 1,
//         ans: 'To replace human developers entirely',
//         isCorrect: false,
//       },
//       {
//         id: 2,
//         ans: 'To collaborate with human developers',
//         isCorrect: true,
//       },
//       {
//         id: 3,
//         ans: 'To write complex novels and stories',
//         isCorrect: false,
//       },
//       {
//         id: 4,
//         ans: 'To diagnose and treat medical conditions',
//         isCorrect: false,
//       },
//     ],
//   },
//   {
//     id: 27,
//     question: 'Which of the following is NOT a capability mentioned for Devin?',
//     answers: [
//       {
//         id: 1,
//         ans: 'Ability to autonomously code complex tasks',
//         isCorrect: false,
//       },
//       {
//         id: 2,
//         ans: 'Capacity for long-term planning while coding',
//         isCorrect: false,
//       },
//       {
//         id: 3,
//         ans: 'Ability to perform surgery',
//         isCorrect: true,
//       },
//     ],
//   },
//   {
//     id: 28,
//     question: 'What is the Turing Test designed to assess?',
//     answers: [
//       {
//         id: 1,
//         ans: 'The speed of an AI system',
//         isCorrect: false,
//       },
//       {
//         id: 2,
//         ans: 'The ability of a machine to exhibit intelligent behavior equivalent to, or indistinguishable from, that of a human',
//         isCorrect: true,
//       },
//       {
//         id: 3,
//         ans: 'The accuracy of an AI system in recognizing images',
//         isCorrect: false,
//       },
//       {
//         id: 4,
//         ans: 'The efficiency of an AI algorithm in solving mathematical problems',
//         isCorrect: false,
//       },
//     ],
//   },
//   {
//     id: 29,
//     question:
//       "What is the term used to describe an AI's ability to learn and improve from experience without being explicitly programmed?",
//     answers: [
//       {
//         id: 1,
//         ans: 'Algorithmic intelligence',
//         isCorrect: false,
//       },
//       {
//         id: 2,
//         ans: 'Machine intelligence',
//         isCorrect: false,
//       },
//       {
//         id: 3,
//         ans: 'Cognitive computing',
//         isCorrect: false,
//       },
//       {
//         id: 4,
//         ans: 'Machine learning',
//         isCorrect: true,
//       },
//     ],
//   },
//   {
//     id: 30,
//     question:
//       'What is the term for the process of an AI system making decisions or performing actions based on data it has been trained on?',
//     answers: [
//       {
//         id: 1,
//         ans: 'Data processing',
//         isCorrect: false,
//       },
//       {
//         id: 2,
//         ans: 'Algorithmic decision-making',
//         isCorrect: false,
//       },
//       {
//         id: 3,
//         ans: 'Machine reasoning',
//         isCorrect: false,
//       },
//       {
//         id: 4,
//         ans: 'Inference',
//         isCorrect: true,
//       },
//     ],
//   },
//   {
//     id: 31,
//     question:
//       'Python String Manipulation: Which method is used to concatenate strings in Python?',
//     answers: [
//       {
//         id: 1,
//         ans: 'concat()',
//         isCorrect: false,
//       },
//       {
//         id: 2,
//         ans: 'plus()',
//         isCorrect: false,
//       },
//       {
//         id: 3,
//         ans: 'join()',
//         isCorrect: false,
//       },
//       {
//         id: 4,
//         ans: 'concatenate()',
//         isCorrect: false,
//       },
//     ],
//   },
//   {
//     id: 32,
//     question:
//       'Python Iteration: What does the `range()` function in Python return?',
//     answers: [
//       {
//         id: 1,
//         ans: 'A list of integers',
//         isCorrect: false,
//       },
//       {
//         id: 2,
//         ans: 'A tuple of integers',
//         isCorrect: false,
//       },
//       {
//         id: 3,
//         ans: 'An iterator of integers',
//         isCorrect: true,
//       },
//       {
//         id: 4,
//         ans: 'A dictionary of integers',
//         isCorrect: false,
//       },
//     ],
//   },
//   {
//     id: 33,
//     question:
//       'Python Conditional Statements: Which operator is used for equality comparison in Python?',
//     answers: [
//       {
//         id: 1,
//         ans: '==',
//         isCorrect: true,
//       },
//       {
//         id: 2,
//         ans: '===',
//         isCorrect: false,
//       },
//       {
//         id: 3,
//         ans: '=',
//         isCorrect: false,
//       },
//       {
//         id: 4,
//         ans: '!=',
//         isCorrect: false,
//       },
//     ],
//   },
//   {
//     id: 34,
//     question:
//       'Python Dictionary Manipulation: Which method is used to remove a key from a dictionary in Python?',
//     answers: [
//       {
//         id: 1,
//         ans: 'remove()',
//         isCorrect: false,
//       },
//       {
//         id: 2,
//         ans: 'delete()',
//         isCorrect: false,
//       },
//       {
//         id: 3,
//         ans: 'pop()',
//         isCorrect: true,
//       },
//       {
//         id: 4,
//         ans: 'discard()',
//         isCorrect: false,
//       },
//     ],
//   },

//   {
//     id: 35,
//     question:
//       'Python File Handling: Which method is used to close a file object in Python?',
//     answers: [
//       {
//         id: 1,
//         ans: 'close()',
//         isCorrect: true,
//       },
//       {
//         id: 2,
//         ans: 'end()',
//         isCorrect: false,
//       },
//       {
//         id: 3,
//         ans: 'finish()',
//         isCorrect: false,
//       },
//       {
//         id: 4,
//         ans: 'stop()',
//         isCorrect: false,
//       },
//     ],
//   },
//   {
//     id: 36,
//     question:
//       'Python Exception Handling: Which of the following is NOT a standard exception handling construct in Python?',
//     answers: [
//       {
//         id: 1,
//         ans: 'try-except',
//         isCorrect: false,
//       },
//       {
//         id: 2,
//         ans: 'try-finally',
//         isCorrect: false,
//       },
//       {
//         id: 3,
//         ans: 'catch-throw',
//         isCorrect: true,
//       },
//       {
//         id: 4,
//         ans: 'try-else',
//         isCorrect: false,
//       },
//     ],
//   },
//   {
//     id: 37,
//     question:
//       'Python Object-Oriented Programming: What is the process of bundling data and methods that work on the data called in Python?',
//     answers: [
//       {
//         id: 1,
//         ans: 'Abstraction',
//         isCorrect: false,
//       },
//       {
//         id: 2,
//         ans: 'Inheritance',
//         isCorrect: false,
//       },
//       {
//         id: 3,
//         ans: 'Encapsulation',
//         isCorrect: true,
//       },
//       {
//         id: 4,
//         ans: 'Polymorphism',
//         isCorrect: false,
//       },
//     ],
//   },
//   {
//     id: 38,
//     question:
//       'Python Libraries: Which library in Python is used for scientific computing?',
//     answers: [
//       {
//         id: 1,
//         ans: 'numpy',
//         isCorrect: true,
//       },
//       {
//         id: 2,
//         ans: 'pandas',
//         isCorrect: false,
//       },
//       {
//         id: 3,
//         ans: 'matplotlib',
//         isCorrect: false,
//       },
//       {
//         id: 4,
//         ans: 'requests',
//         isCorrect: false,
//       },
//     ],
//   },
//   {
//     id: 39,
//     question:
//       'Python Generators: Which keyword is used to define a generator function in Python?',
//     answers: [
//       {
//         id: 1,
//         ans: 'def',
//         isCorrect: false,
//       },
//       {
//         id: 2,
//         ans: 'yield',
//         isCorrect: true,
//       },
//       {
//         id: 3,
//         ans: 'return',
//         isCorrect: false,
//       },
//       {
//         id: 4,
//         ans: 'gen',
//         isCorrect: false,
//       },
//     ],
//   },
//   {
//     id: 40,
//     question:
//       'Python List Comprehension: Which of the following constructs can be used to create a new list based on an existing iterable in Python?',
//     answers: [
//       {
//         id: 1,
//         ans: 'map()',
//         isCorrect: false,
//       },
//       {
//         id: 2,
//         ans: 'filter()',
//         isCorrect: false,
//       },
//       {
//         id: 3,
//         ans: 'reduce()',
//         isCorrect: false,
//       },
//       {
//         id: 4,
//         ans: 'list comprehension',
//         isCorrect: true,
//       },
//     ],
//   },
//   {
//     id: 41,
//     question:
//       'C Control Structures: Which control structure in C is used to iterate a statement or group of statements until a particular condition is satisfied?',
//     answers: [
//       {
//         id: 1,
//         ans: 'if-else',
//         isCorrect: false,
//       },
//       {
//         id: 2,
//         ans: 'while',
//         isCorrect: true,
//       },
//       {
//         id: 3,
//         ans: 'for',
//         isCorrect: false,
//       },
//       {
//         id: 4,
//         ans: 'switch',
//         isCorrect: false,
//       },
//     ],
//   },
//   {
//     id: 42,
//     question:
//       'C Functions: What is the default return type of a function in C if not specified explicitly?',
//     answers: [
//       {
//         id: 1,
//         ans: 'void',
//         isCorrect: false,
//       },
//       {
//         id: 2,
//         ans: 'int',
//         isCorrect: true,
//       },
//       {
//         id: 3,
//         ans: 'float',
//         isCorrect: false,
//       },
//       {
//         id: 4,
//         ans: 'char',
//         isCorrect: false,
//       },
//     ],
//   },
//   {
//     id: 43,
//     question:
//       'C Arrays: How do you access the nth element of an array named `arr` in C?',
//     answers: [
//       {
//         id: 1,
//         ans: 'arr(n)',
//         isCorrect: false,
//       },
//       {
//         id: 2,
//         ans: 'arr[n]',
//         isCorrect: true,
//       },
//       {
//         id: 3,
//         ans: 'arr.n',
//         isCorrect: false,
//       },
//       {
//         id: 4,
//         ans: 'arr->n',
//         isCorrect: false,
//       },
//     ],
//   },
//   {
//     id: 44,
//     question: 'C Strings: Which function is used to compare two strings in C?',
//     answers: [
//       {
//         id: 1,
//         ans: 'strcmp()',
//         isCorrect: true,
//       },
//       {
//         id: 2,
//         ans: 'strcat()',
//         isCorrect: false,
//       },
//       {
//         id: 3,
//         ans: 'strcpy()',
//         isCorrect: false,
//       },
//       {
//         id: 4,
//         ans: 'strlen()',
//         isCorrect: false,
//       },
//     ],
//   },
//   {
//     id: 45,
//     question: 'C File Handling: Which function is used to open a file in C?',
//     answers: [
//       {
//         id: 1,
//         ans: 'open()',
//         isCorrect: false,
//       },
//       {
//         id: 2,
//         ans: 'fopen()',
//         isCorrect: true,
//       },
//       {
//         id: 3,
//         ans: 'read()',
//         isCorrect: false,
//       },
//       {
//         id: 4,
//         ans: 'file_open()',
//         isCorrect: false,
//       },
//     ],
//   },
//   {
//     id: 46,
//     question:
//       'C Memory Allocation: Which function is used to allocate memory dynamically in C?',
//     answers: [
//       {
//         id: 1,
//         ans: 'alloc()',
//         isCorrect: false,
//       },
//       {
//         id: 2,
//         ans: 'calloc()',
//         isCorrect: false,
//       },
//       {
//         id: 3,
//         ans: 'malloc()',
//         isCorrect: true,
//       },
//       {
//         id: 4,
//         ans: 'memalloc()',
//         isCorrect: false,
//       },
//     ],
//   },
//   {
//     id: 47,
//     question:
//       "C Variables: What is the correct syntax to declare an integer variable named 'age' in C?",
//     answers: [
//       {
//         id: 1,
//         ans: 'int age;',
//         isCorrect: true,
//       },
//       {
//         id: 2,
//         ans: 'integer age;',
//         isCorrect: false,
//       },
//       {
//         id: 3,
//         ans: 'age = int;',
//         isCorrect: false,
//       },
//       {
//         id: 4,
//         ans: 'age int;',
//         isCorrect: false,
//       },
//     ],
//   },
//   {
//     id: 48,
//     question:
//       'C Comments: Which symbol is used to write a single-line comment in C?',
//     answers: [
//       {
//         id: 1,
//         ans: '//',
//         isCorrect: true,
//       },
//       {
//         id: 2,
//         ans: '--',
//         isCorrect: false,
//       },
//       {
//         id: 3,
//         ans: '/* */',
//         isCorrect: false,
//       },
//       {
//         id: 4,
//         ans: '#',
//         isCorrect: false,
//       },
//     ],
//   },
//   {
//     id: 49,
//     question: 'C Keywords: Which keyword is used to define a constant in C?',
//     answers: [
//       {
//         id: 1,
//         ans: 'const',
//         isCorrect: true,
//       },
//       {
//         id: 2,
//         ans: 'constant',
//         isCorrect: false,
//       },
//       {
//         id: 3,
//         ans: 'define',
//         isCorrect: false,
//       },
//       {
//         id: 4,
//         ans: 'defineconst',
//         isCorrect: false,
//       },
//     ],
//   },
//   {
//     id: 50,
//     question:
//       'C Decision Making: Which construct is used to execute a block of code only if a condition is true in C?',
//     answers: [
//       {
//         id: 1,
//         ans: 'while loop',
//         isCorrect: false,
//       },
//       {
//         id: 2,
//         ans: 'for loop',
//         isCorrect: false,
//       },
//       {
//         id: 3,
//         ans: 'if statement',
//         isCorrect: true,
//       },
//       {
//         id: 4,
//         ans: 'switch statement',
//         isCorrect: false,
//       },
//     ],
//   },
//   {
//     id: 51,
//     question:
//       'Logical Reasoning: If all cats can fly, and Fluffy is a cat, what can we conclude?',
//     answers: [
//       {
//         id: 1,
//         ans: 'Fluffy can fly.',
//         isCorrect: true,
//       },
//       {
//         id: 2,
//         ans: 'Fluffy cannot fly.',
//         isCorrect: false,
//       },
//       {
//         id: 3,
//         ans: 'Fluffy might be able to fly.',
//         isCorrect: false,
//       },
//       {
//         id: 4,
//         ans: 'We cannot conclude anything.',
//         isCorrect: false,
//       },
//     ],
//   },
//   {
//     id: 52,
//     question:
//       'Logical Reasoning: If all A are B, and all B are C, what can we conclude?',
//     answers: [
//       {
//         id: 1,
//         ans: 'All A are C.',
//         isCorrect: true,
//       },
//       {
//         id: 2,
//         ans: 'All C are A.',
//         isCorrect: false,
//       },
//       {
//         id: 3,
//         ans: 'Some A are C.',
//         isCorrect: false,
//       },
//       {
//         id: 4,
//         ans: 'We cannot conclude anything.',
//         isCorrect: false,
//       },
//     ],
//   },
//   {
//     id: 53,
//     question:
//       'Logical Reasoning: If some cars are blue, and all blue things are elephants, what can we conclude about cars?',
//     answers: [
//       {
//         id: 1,
//         ans: 'Some cars are elephants.',
//         isCorrect: false,
//       },
//       {
//         id: 2,
//         ans: 'All cars are blue.',
//         isCorrect: false,
//       },
//       {
//         id: 3,
//         ans: 'All blue cars are elephants.',
//         isCorrect: true,
//       },
//       {
//         id: 4,
//         ans: 'Some elephants are cars.',
//         isCorrect: false,
//       },
//     ],
//   },
//   {
//     id: 54,
//     question:
//       'Logical Reasoning: If no apples are oranges, and some fruits are apples, what can we conclude about oranges?',
//     answers: [
//       {
//         id: 1,
//         ans: 'Some fruits are oranges.',
//         isCorrect: false,
//       },
//       {
//         id: 2,
//         ans: 'All fruits are oranges.',
//         isCorrect: false,
//       },
//       {
//         id: 3,
//         ans: 'No fruits are oranges.',
//         isCorrect: true,
//       },
//       {
//         id: 4,
//         ans: 'We cannot conclude anything.',
//         isCorrect: false,
//       },
//     ],
//   },
//   {
//     id: 55,
//     question:
//       'Logical Reasoning: If some squares are circles, and all circles are triangles, what can we conclude about squares?',
//     answers: [
//       {
//         id: 1,
//         ans: 'Some squares are triangles.',
//         isCorrect: true,
//       },
//       {
//         id: 2,
//         ans: 'All squares are circles.',
//         isCorrect: false,
//       },
//       {
//         id: 3,
//         ans: 'Some circles are squares.',
//         isCorrect: false,
//       },
//       {
//         id: 4,
//         ans: 'We cannot conclude anything.',
//         isCorrect: false,
//       },
//     ],
//   },
//   {
//     id: 56,
//     question:
//       'Logical Reasoning: If no birds can fly, and all penguins are birds, what can we conclude about penguins?',
//     answers: [
//       {
//         id: 1,
//         ans: 'All penguins can fly.',
//         isCorrect: false,
//       },
//       {
//         id: 2,
//         ans: 'Some penguins can fly.',
//         isCorrect: false,
//       },
//       {
//         id: 3,
//         ans: 'No penguins can fly.',
//         isCorrect: true,
//       },
//       {
//         id: 4,
//         ans: 'We cannot conclude anything.',
//         isCorrect: false,
//       },
//     ],
//   },
//   {
//     id: 57,
//     question:
//       'Logical Reasoning: If all monkeys are mammals, and some mammals are pets, what can we conclude about monkeys?',
//     answers: [
//       {
//         id: 1,
//         ans: 'All monkeys are pets.',
//         isCorrect: false,
//       },
//       {
//         id: 2,
//         ans: 'Some monkeys are pets.',
//         isCorrect: true,
//       },
//       {
//         id: 3,
//         ans: 'No monkeys are pets.',
//         isCorrect: false,
//       },
//       {
//         id: 4,
//         ans: 'We cannot conclude anything.',
//         isCorrect: false,
//       },
//     ],
//   },
//   {
//     id: 58,
//     question:
//       'Logical Reasoning: If all dogs bark, and Fido is a dog, what can we conclude?',
//     answers: [
//       {
//         id: 1,
//         ans: 'Fido barks.',
//         isCorrect: true,
//       },
//       {
//         id: 2,
//         ans: 'Fido does not bark.',
//         isCorrect: false,
//       },
//       {
//         id: 3,
//         ans: 'Fido might bark.',
//         isCorrect: false,
//       },
//       {
//         id: 4,
//         ans: 'We cannot conclude anything.',
//         isCorrect: false,
//       },
//     ],
//   },
//   {
//     id: 59,
//     question:
//       'Logical Reasoning: If all trees have leaves, and a maple tree is a tree, what can we conclude about maple trees?',
//     answers: [
//       {
//         id: 1,
//         ans: 'Maple trees have leaves.',
//         isCorrect: true,
//       },
//       {
//         id: 2,
//         ans: 'Maple trees do not have leaves.',
//         isCorrect: false,
//       },
//       {
//         id: 3,
//         ans: 'Some maple trees have leaves.',
//         isCorrect: false,
//       },
//       {
//         id: 4,
//         ans: 'All maple trees have leaves.',
//         isCorrect: false,
//       },
//     ],
//   },
//   {
//     id: 60,
//     question:
//       'Logical Reasoning: If all A are B, and some B are C, what can we conclude about A and C?',
//     answers: [
//       {
//         id: 1,
//         ans: 'All A are C.',
//         isCorrect: false,
//       },
//       {
//         id: 2,
//         ans: 'Some A are C.',
//         isCorrect: true,
//       },
//       {
//         id: 3,
//         ans: 'No A are C.',
//         isCorrect: false,
//       },
//       {
//         id: 4,
//         ans: 'We cannot conclude anything.',
//         isCorrect: false,
//       },
//     ],
//   },
// ];

export const quizQuestions = [
  {
    id: 1,
    question: "What is the output of `console.log(typeof [])` in JavaScript?",
    answers: [
      { id: 1, ans: "'array'", isCorrect: false },
      { id: 2, ans: "'object'", isCorrect: true },
      { id: 3, ans: "'undefined'", isCorrect: false },
      { id: 4, ans: "'function'", isCorrect: false },
    ],
  },
  {
    id: 2,
    question: "Which sorting algorithm has the best average case performance?",
    answers: [
      { id: 1, ans: "Bubble Sort", isCorrect: false },
      { id: 2, ans: "Selection Sort", isCorrect: false },
      { id: 3, ans: "Merge Sort", isCorrect: true },
      { id: 4, ans: "Insertion Sort", isCorrect: false },
    ],
  },
  {
    id: 3,
    question: "What does the `this` keyword refer to in a JavaScript arrow function?",
    answers: [
      { id: 1, ans: "The global object", isCorrect: true },
      { id: 2, ans: "The function itself", isCorrect: false },
      { id: 3, ans: "The calling object", isCorrect: false },
      { id: 4, ans: "Undefined", isCorrect: false },
    ],
  },
  {
    id: 4,
    question: "Which data structure is used for LRU cache implementation?",
    answers: [
      { id: 1, ans: "Stack", isCorrect: false },
      { id: 2, ans: "Queue", isCorrect: false },
      { id: 3, ans: "HashMap + Doubly Linked List", isCorrect: true },
      { id: 4, ans: "Binary Search Tree", isCorrect: false },
    ],
  },
  {
    id: 5,
    question: "Which of the following is NOT a NoSQL database?",
    answers: [
      { id: 1, ans: "MongoDB", isCorrect: false },
      { id: 2, ans: "Redis", isCorrect: false },
      { id: 3, ans: "PostgreSQL", isCorrect: true },
      { id: 4, ans: "Cassandra", isCorrect: false },
    ],
  },
  {
    id: 6,
    question: "What is the time complexity of binary search?",
    answers: [
      { id: 1, ans: "O(n)", isCorrect: false },
      { id: 2, ans: "O(log n)", isCorrect: true },
      { id: 3, ans: "O(n log n)", isCorrect: false },
      { id: 4, ans: "O(1)", isCorrect: false },
    ],
  },
  {
    id: 7,
    question: "Which protocol is used for secure communication over the web?",
    answers: [
      { id: 1, ans: "HTTP", isCorrect: false },
      { id: 2, ans: "FTP", isCorrect: false },
      { id: 3, ans: "HTTPS", isCorrect: true },
      { id: 4, ans: "SMTP", isCorrect: false },
    ],
  },
  {
    id: 8,
    question: "What is the primary advantage of using a linked list over an array?",
    answers: [
      { id: 1, ans: "Faster access time", isCorrect: false },
      { id: 2, ans: "Dynamic size allocation", isCorrect: true },
      { id: 3, ans: "Less memory usage", isCorrect: false },
      { id: 4, ans: "Better cache locality", isCorrect: false },
    ],
  },
  {
    id: 9,
    question: "Which of the following is a feature of functional programming?",
    answers: [
      { id: 1, ans: "Stateful functions", isCorrect: false },
      { id: 2, ans: "Side effects", isCorrect: false },
      { id: 3, ans: "First-class functions", isCorrect: true },
      { id: 4, ans: "Mutable data", isCorrect: false },
    ],
  },
  {
    id: 10,
    question: "What is the worst-case time complexity of QuickSort?",
    answers: [
      { id: 1, ans: "O(n)", isCorrect: false },
      { id: 2, ans: "O(log n)", isCorrect: false },
      { id: 3, ans: "O(n log n)", isCorrect: false },
      { id: 4, ans: "O(n²)", isCorrect: true },
    ],
  },
  {
    id: 11,
    question: "Which of the following is a characteristic of a pure function in functional programming?",
    answers: [
      { id: 1, ans: "It modifies global variables", isCorrect: false },
      { id: 2, ans: "It does not produce side effects", isCorrect: true },
      { id: 3, ans: "It relies on external inputs", isCorrect: false },
      { id: 4, ans: "It alters state", isCorrect: false },
    ],
  },
  {
    id: 12,
    question: "Which JavaScript method is used to remove the last element from an array?",
    answers: [
      { id: 1, ans: "shift()", isCorrect: false },
      { id: 2, ans: "pop()", isCorrect: true },
      { id: 3, ans: "slice()", isCorrect: false },
      { id: 4, ans: "splice()", isCorrect: false },
    ],
  },
  {
    id: 13,
    question: "What is the time complexity of inserting an element at the beginning of an array in JavaScript?",
    answers: [
      { id: 1, ans: "O(1)", isCorrect: false },
      { id: 2, ans: "O(n)", isCorrect: true },
      { id: 3, ans: "O(log n)", isCorrect: false },
      { id: 4, ans: "O(n log n)", isCorrect: false },
    ],
  },
  {
    id: 14,
    question: "Which of the following is an example of a relational database?",
    answers: [
      { id: 1, ans: "MongoDB", isCorrect: false },
      { id: 2, ans: "Cassandra", isCorrect: false },
      { id: 3, ans: "MySQL", isCorrect: true },
      { id: 4, ans: "Redis", isCorrect: false },
    ],
  },
  {
    id: 15,
    question: "What does SQL stand for?",
    answers: [
      { id: 1, ans: "Structured Question Language", isCorrect: false },
      { id: 2, ans: "Sequential Query Language", isCorrect: false },
      { id: 3, ans: "Structured Query Language", isCorrect: true },
      { id: 4, ans: "Standard Query Language", isCorrect: false },
    ],
  },
  {
    id: 16,
    question: "Which HTTP status code indicates a 'Not Found' error?",
    answers: [
      { id: 1, ans: "200", isCorrect: false },
      { id: 2, ans: "301", isCorrect: false },
      { id: 3, ans: "404", isCorrect: true },
      { id: 4, ans: "500", isCorrect: false },
    ],
  },
  {
    id: 17,
    question: "Which programming language is known for its use in data science?",
    answers: [
      { id: 1, ans: "C++", isCorrect: false },
      { id: 2, ans: "Python", isCorrect: true },
      { id: 3, ans: "Java", isCorrect: false },
      { id: 4, ans: "Ruby", isCorrect: false },
    ],
  },
  {
    id: 18,
    question: "What does the acronym 'API' stand for?",
    answers: [
      { id: 1, ans: "Advanced Programming Interface", isCorrect: false },
      { id: 2, ans: "Application Programming Interface", isCorrect: true },
      { id: 3, ans: "Automated Process Integration", isCorrect: false },
      { id: 4, ans: "Application Processing Instruction", isCorrect: false },
    ],
  },
  {
    id: 19,
    question: "Which of the following is a cloud computing platform?",
    answers: [
      { id: 1, ans: "Windows", isCorrect: false },
      { id: 2, ans: "Linux", isCorrect: false },
      { id: 3, ans: "AWS", isCorrect: true },
      { id: 4, ans: "Docker", isCorrect: false },
    ],
  },
  {
    id: 20,
    question: "Which sorting algorithm is the best choice for nearly sorted data?",
    answers: [
      { id: 1, ans: "Bubble Sort", isCorrect: false },
      { id: 2, ans: "Insertion Sort", isCorrect: true },
      { id: 3, ans: "QuickSort", isCorrect: false },
      { id: 4, ans: "Merge Sort", isCorrect: false },
    ],
  },
  {
    id: 31,
    question: "Which of the following is the correct way to write a comment in JavaScript?",
    answers: [
      { id: 1, ans: "/* This is a comment */", isCorrect: true },
      { id: 2, ans: "// This is a comment //", isCorrect: false },
      { id: 3, ans: "<!-- This is a comment -->", isCorrect: false },
      { id: 4, ans: "' This is a comment '", isCorrect: false },
    ],
  },
  {
    id: 32,
    question: "Which JavaScript function is used to execute code after a specified time interval?",
    answers: [
      { id: 1, ans: "setInterval()", isCorrect: false },
      { id: 2, ans: "setTimeout()", isCorrect: true },
      { id: 3, ans: "delay()", isCorrect: false },
      { id: 4, ans: "wait()", isCorrect: false },
    ],
  },
  {
    id: 33,
    question: "Which HTML attribute is used to specify an image source?",
    answers: [
      { id: 1, ans: "href", isCorrect: false },
      { id: 2, ans: "src", isCorrect: true },
      { id: 3, ans: "alt", isCorrect: false },
      { id: 4, ans: "link", isCorrect: false },
    ],
  },
  {
    id: 34,
    question: "Which JavaScript keyword is used to define a class?",
    answers: [
      { id: 1, ans: "class", isCorrect: true },
      { id: 2, ans: "function", isCorrect: false },
      { id: 3, ans: "define", isCorrect: false },
      { id: 4, ans: "new", isCorrect: false },
    ],
  },
  {
    id: 35,
    question: "Which method is used to remove the last element from an array in JavaScript?",
    answers: [
      { id: 1, ans: "shift()", isCorrect: false },
      { id: 2, ans: "pop()", isCorrect: true },
      { id: 3, ans: "splice()", isCorrect: false },
      { id: 4, ans: "remove()", isCorrect: false },
    ],
  },
  {
    id: 36,
    question: "Which CSS property is used to control the spacing between lines of text?",
    answers: [
      { id: 1, ans: "letter-spacing", isCorrect: false },
      { id: 2, ans: "line-height", isCorrect: true },
      { id: 3, ans: "text-indent", isCorrect: false },
      { id: 4, ans: "word-spacing", isCorrect: false },
    ],
  },
  {
    id: 37,
    question: "Which JavaScript operator is used to compare both value and type?",
    answers: [
      { id: 1, ans: "==", isCorrect: false },
      { id: 2, ans: "===", isCorrect: true },
      { id: 3, ans: "!=", isCorrect: false },
      { id: 4, ans: "!==", isCorrect: false },
    ],
  },
  {
    id: 38,
    question: "Which Git command is used to check the status of the working directory?",
    answers: [
      { id: 1, ans: "git check", isCorrect: false },
      { id: 2, ans: "git status", isCorrect: true },
      { id: 3, ans: "git log", isCorrect: false },
      { id: 4, ans: "git diff", isCorrect: false },
    ],
  },
  {
    id: 39,
    question: "Which HTTP method is typically used to update an existing resource?",
    answers: [
      { id: 1, ans: "GET", isCorrect: false },
      { id: 2, ans: "POST", isCorrect: false },
      { id: 3, ans: "PUT", isCorrect: true },
      { id: 4, ans: "DELETE", isCorrect: false },
    ],
  },
  {
    id: 40,
    question: "Which of the following is a JavaScript package manager?",
    answers: [
      { id: 1, ans: "npm", isCorrect: true },
      { id: 2, ans: "pip", isCorrect: false },
      { id: 3, ans: "composer", isCorrect: false },
      { id: 4, ans: "maven", isCorrect: false },
    ],
  },
  {
    id: 41,
    question: "Which function is used to parse a string into a floating-point number in JavaScript?",
    answers: [
      { id: 1, ans: "parseInt()", isCorrect: false },
      { id: 2, ans: "parseFloat()", isCorrect: true },
      { id: 3, ans: "Number()", isCorrect: false },
      { id: 4, ans: "toFixed()", isCorrect: false },
    ],
  },
  {
    id: 42,
    question: "Which event is triggered when a user clicks on an HTML element?",
    answers: [
      { id: 1, ans: "onhover", isCorrect: false },
      { id: 2, ans: "onclick", isCorrect: true },
      { id: 3, ans: "onchange", isCorrect: false },
      { id: 4, ans: "onload", isCorrect: false },
    ],
  },
  {
    id: 43,
    question: "Which JavaScript method is used to round a number to the nearest integer?",
    answers: [
      { id: 1, ans: "Math.round()", isCorrect: true },
      { id: 2, ans: "Math.ceil()", isCorrect: false },
      { id: 3, ans: "Math.floor()", isCorrect: false },
      { id: 4, ans: "Math.trunc()", isCorrect: false },
    ],
  },
  {
    id: 44,
    question: "Which keyword is used to declare a constant variable in JavaScript?",
    answers: [
      { id: 1, ans: "let", isCorrect: false },
      { id: 2, ans: "var", isCorrect: false },
      { id: 3, ans: "const", isCorrect: true },
      { id: 4, ans: "static", isCorrect: false },
    ],
  },
  {
    id: 45,
    question: "Which method is used to convert a JavaScript object into a JSON string?",
    answers: [
      { id: 1, ans: "JSON.stringify()", isCorrect: true },
      { id: 2, ans: "JSON.parse()", isCorrect: false },
      { id: 3, ans: "toString()", isCorrect: false },
      { id: 4, ans: "serialize()", isCorrect: false },
    ],
  },
  {
    id: 46,
    question: "Which JavaScript method is used to check if an array includes a specific element?",
    answers: [
      { id: 1, ans: "find()", isCorrect: false },
      { id: 2, ans: "includes()", isCorrect: true },
      { id: 3, ans: "indexOf()", isCorrect: false },
      { id: 4, ans: "some()", isCorrect: false },
    ],
  },
  {
    id: 47,
    question: "Which HTTP status code represents 'Not Found'?",
    answers: [
      { id: 1, ans: "200", isCorrect: false },
      { id: 2, ans: "301", isCorrect: false },
      { id: 3, ans: "404", isCorrect: true },
      { id: 4, ans: "500", isCorrect: false },
    ],
  },
  {
    id: 48,
    question: "Which method is used to add a new element at the end of an array in JavaScript?",
    answers: [
      { id: 1, ans: "push()", isCorrect: true },
      { id: 2, ans: "unshift()", isCorrect: false },
      { id: 3, ans: "append()", isCorrect: false },
      { id: 4, ans: "concat()", isCorrect: false },
    ],
  },
  {
    id: 49,
    question: "Which CSS property is used to set the background color of an element?",
    answers: [
      { id: 1, ans: "color", isCorrect: false },
      { id: 2, ans: "background-color", isCorrect: true },
      { id: 3, ans: "border-color", isCorrect: false },
      { id: 4, ans: "fill-color", isCorrect: false },
    ],
  },
  {
    id: 50,
    question: "Which function is used to merge two or more arrays in JavaScript?",
    answers: [
      { id: 1, ans: "merge()", isCorrect: false },
      { id: 2, ans: "concat()", isCorrect: true },
      { id: 3, ans: "combine()", isCorrect: false },
      { id: 4, ans: "join()", isCorrect: false },
    ],
  },
  {
    id: 51,
    question: "Which JavaScript keyword is used to define a class?",
    answers: [
      { id: 1, ans: "function", isCorrect: false },
      { id: 2, ans: "class", isCorrect: true },
      { id: 3, ans: "object", isCorrect: false },
      { id: 4, ans: "new", isCorrect: false },
    ],
  },
  {
    id: 52,
    question: "Which method is used to remove the last element from an array in JavaScript?",
    answers: [
      { id: 1, ans: "shift()", isCorrect: false },
      { id: 2, ans: "pop()", isCorrect: true },
      { id: 3, ans: "slice()", isCorrect: false },
      { id: 4, ans: "splice()", isCorrect: false },
    ],
  },
  {
    id: 53,
    question: "Which operator is used for strict equality comparison in JavaScript?",
    answers: [
      { id: 1, ans: "==", isCorrect: false },
      { id: 2, ans: "===", isCorrect: true },
      { id: 3, ans: "!=", isCorrect: false },
      { id: 4, ans: "!==", isCorrect: false },
    ],
  },
  {
    id: 54,
    question: "Which method is used to execute a function at regular intervals in JavaScript?",
    answers: [
      { id: 1, ans: "setTimeout()", isCorrect: false },
      { id: 2, ans: "setInterval()", isCorrect: true },
      { id: 3, ans: "clearInterval()", isCorrect: false },
      { id: 4, ans: "requestAnimationFrame()", isCorrect: false },
    ],
  },
  {
    id: 55,
    question: "Which JavaScript method is used to convert a JSON string into an object?",
    answers: [
      { id: 1, ans: "JSON.stringify()", isCorrect: false },
      { id: 2, ans: "JSON.parse()", isCorrect: true },
      { id: 3, ans: "toObject()", isCorrect: false },
      { id: 4, ans: "deserialize()", isCorrect: false },
    ],
  },
  {
    id: 56,
    question: "Which JavaScript loop ensures that the loop body runs at least once?",
    answers: [
      { id: 1, ans: "for loop", isCorrect: false },
      { id: 2, ans: "while loop", isCorrect: false },
      { id: 3, ans: "do-while loop", isCorrect: true },
      { id: 4, ans: "foreach loop", isCorrect: false },
    ],
  },
  {
    id: 57,
    question: "Which HTTP request method is used to send data to a server?",
    answers: [
      { id: 1, ans: "GET", isCorrect: false },
      { id: 2, ans: "POST", isCorrect: true },
      { id: 3, ans: "DELETE", isCorrect: false },
      { id: 4, ans: "HEAD", isCorrect: false },
    ],
  },
  {
    id: 58,
    question: "Which JavaScript method is used to find the first element that matches a condition in an array?",
    answers: [
      { id: 1, ans: "filter()", isCorrect: false },
      { id: 2, ans: "find()", isCorrect: true },
      { id: 3, ans: "map()", isCorrect: false },
      { id: 4, ans: "forEach()", isCorrect: false },
    ],
  },
  {
    id: 59,
    question: "Which CSS property is used to change the text color of an element?",
    answers: [
      { id: 1, ans: "font-color", isCorrect: false },
      { id: 2, ans: "text-color", isCorrect: false },
      { id: 3, ans: "color", isCorrect: true },
      { id: 4, ans: "foreground", isCorrect: false },
    ],
  },
  {
    id: 60,
    question: "Which JavaScript function is used to execute a function after a specified delay?",
    answers: [
      { id: 1, ans: "setTimeout()", isCorrect: true },
      { id: 2, ans: "setInterval()", isCorrect: false },
      { id: 3, ans: "requestAnimationFrame()", isCorrect: false },
      { id: 4, ans: "delay()", isCorrect: false },
    ],
  }  
];







