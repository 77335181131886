import React, { useEffect, useState } from 'react';
import { quizQuestions } from '../QuizData/quizdata';
import { useNavigate } from 'react-router-dom';
import Timer from './Timer';
import axios from 'axios';
import toast, { ToastBar, Toaster } from 'react-hot-toast';

const Quiz = ({ startTimer, setName }) => {
  const [qNumber, setQNumber] = useState(0);
  const [Score, setScore] = useState(0);
  const [displayScore, setDisplayScore] = useState(false);
  const [clickedAnswer, setclickedAnswer] = useState(0);
  const [seconds, setSeconds] = useState(3600);
  const [time, setTime] = useState(true);

  const navigate = useNavigate();

  const data = localStorage.getItem('formData');
  const datas = JSON.parse(data);
  datas['Score'] = Score;

  useEffect(() => {
    localStorage.setItem('timer', true);
  }, []);

  setTimeout(() => {
    localStorage.setItem('timer', false);
    setTime(false);
  }, 3900000);

  useEffect(() => {
    if (displayScore) {
      const quizScoreObject = {
        Score: Score+1,
        totalQuestions: quizQuestions.length,
      };
      localStorage.setItem('quizScore', JSON.stringify(quizScoreObject));
    }
  }, [displayScore, Score]);

  useEffect(() => {
    // console.log('time', time);
    if (time === false) {
      const formDatas = new FormData();

      Object.keys(datas).forEach((key) => {
        formDatas.append(key, datas[key]);
      });
      setDisplayScore(true);
      (async function callApi() {
        // console.log('sucessssssssssssssss');
        axios
          .post(
            'https://script.google.com/macros/s/AKfycbwXP3b8OWRIjWsV44LENxMm1qkkjpgPsKlncnJkpKoJf5JW62hzONSTx5u4Hk6sU9gUfA/exec',
            formDatas
          )
          .then((data) => {
            // console.log(data);
          })
          .catch((error) => {
            // console.log(error);
          });
      })();
      toast.error('Timeout!, Your test has been submitted', {
        // position: 'bottom-center',
        position: 'center-right',
        duration: 3000,
        style: {
          width: '400px',
          fontSize: '24px',
          background: 'green',
          color: '#fff',
        },
      });
      // setDisplayScore(true);
      navigate('/');
    }
  }, [time]);

  const selectedAnswer = (selected, id) => {
    setclickedAnswer(id);
    setTimeout(async () => {
      const nextQ = qNumber + 1;
      // setclickedAnswer(0);
      if (nextQ < quizQuestions.length) {
        setQNumber(nextQ);
      }
      if (nextQ === quizQuestions.length) {
        toast.success('Your test has been Submitted!', {
          // position: 'bottom-center',
          position: 'center-right',
          duration: 3000,
          style: {
            width: '400px',
            fontSize: '24px',
            background: 'green',
            color: '#fff',
          },
        });

        const formDatas = new FormData();

        Object.keys(datas).forEach((key) => {
          formDatas.append(key, datas[key]);
        });

        await axios
          .post(
            'https://script.google.com/macros/s/AKfycbwXP3b8OWRIjWsV44LENxMm1qkkjpgPsKlncnJkpKoJf5JW62hzONSTx5u4Hk6sU9gUfA/exec',
            formDatas
          )
          .then((data) => {
            // console.log(data);
          })
          .catch((error) => {
            // console.log(error);
          });

        // console.log('sucessssssssssssssss');
        setDisplayScore(true);
      }
      if (selected === true) {
        setScore((score) => score + 1);
      }
      // setSeconds(20);
    }, 600);
  };

  return (
    <div className="Main_Div">
      <Toaster>
        {(t) => (
          <ToastBar
            toast={t}
            style={{
              ...t.style,
              animation: t.visible
                ? 'custom-enter 3s ease'
                : 'custom-exit 1s ease',
            }}
          />
        )}
      </Toaster>
      <div className="Quiz_Div">
        {displayScore ? (
          <>
            <p>
              Your score : {Score} / {quizQuestions.length}
            </p>
            <div className="buttons_div">
              {/* <button
                onClick={() => {
                  navigate(-1);
                  setName('');
                }}
              >
                Retry
              </button> */}
              <button
                onClick={() => {
                  navigate('/');
                  setName('');
                }}
              >
                Exit
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="quiz_top_div">
              <h2>
                {qNumber + 1} of {quizQuestions.length}
              </h2>
              {startTimer && (
                <Timer
                  setDisplayScore={setDisplayScore}
                  score={Score}
                  setQNumber={setQNumber}
                  seconds={seconds}
                  setSeconds={setSeconds}
                  qNumber={qNumber}
                  quizQuestions={quizQuestions}
                />
              )}
            </div>

            <h5>{quizQuestions[qNumber].question}</h5>
            <ul>
              {quizQuestions[qNumber].answers.map((answer) => (
                <li
                  className={clickedAnswer === answer.id ? `answerCss` : null}
                  key={answer.id}
                  onClick={() => selectedAnswer(answer.isCorrect, answer.id)}
                >
                  {answer.ans}
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
    </div>
  );
};
export default Quiz;
