import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from './Loader';

const Home = ({ setName, name }) => {
  const [formData, setFormData] = useState({
    Name: '',
    Email: '',
    Phone: '',
    College: '',
    Academic_year: '',
    Department: '',
  });

  const [loading,setLoading]= useState(false)
  console.log(formData);

  const [errors, setErrors] = useState({
    Name: false,
    Email: false,
    Phone: false,
    College: false,
    Academic_year: false,
    Department: false,
  });

  const navigate = useNavigate();

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const validatePhone = (phone) => {
    return /^\d{10}$/.test(phone);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: false,
    });
  };

  const start = async (event) => {
    event.preventDefault();
    const newErrors = {};
    let hasError = false;

    for (const key in formData) {
      if (formData[key].trim() === '') {
        newErrors[key] = true;
        hasError = true;
      }
    }

    if (formData.Email && !validateEmail(formData.Email)) {
      newErrors.Email = true;
      hasError = true;
    }

    if (formData.Phone && !validatePhone(formData.Phone)) {
      newErrors.Phone = true;
      hasError = true;
    }

    if (!hasError) {
      console.log(formData);
      setLoading(true)
      const formDatas = new FormData();
      
      Object.keys(formData).forEach((key) => {
        formDatas.append(key, formData[key]);
      });
      
      // axios.post('https://script.google.com/macros/s/AKfycbzpnKdpjxmuo4kdFz39wC1IZwACRYfGTQY_xws81FvlLYQUKZVwgfJP5noocDdekLbV/exec', formDatas).then((res) => {
      axios.post('https://script.google.com/macros/s/AKfycbwOjp2y2_-yE4BrrmW-vWqc2YCIWn18T8AtPG-npTAR6_CNr-EduiEklU9d5NvAA2tJOA/exec', formDatas).then((res) => {
        console.log(res);
        
        setName(formData.Name);
        const formDataJSON = JSON.stringify(formData);
        localStorage.setItem('formData', formDataJSON);
        navigate('/instructions');
        setLoading(false)
      })

    }

    setErrors(newErrors);
  };

  return (
    <div className="Main_Div">
      <div className="Home_Div">
        <form onSubmit={start}>
          <img
            className="logo"
            src="/company_logo_edited1.png"
            alt=""
            width={'400'}
            height={'109'}
            style={{ textAlign: 'center' }}
          />
          <h1>Assessment Exam</h1>
          <p className="first_p">Enter your details to start the test</p>
          <input
            type="text"
            name="Name"
            value={formData.Name}
            placeholder="Your name"
            autoComplete="off"
            onChange={handleInputChange}
          />
          {errors.Name && <p className="error">Please enter your name</p>}
          <input
            type="email"
            name="Email"
            value={formData.Email}
            placeholder="Your email"
            autoComplete="off"
            onChange={handleInputChange}
          />
          {errors.Email && <p className="error">Please enter a valid email</p>}
          {/* <input
            type="number"
            name="Phone"
            value={formData.Phone}
            placeholder="Your phone"
            autoComplete="off"
            onChange={handleInputChange}
          /> */}
          <input
            type="text"
            name="Phone"
            value={formData.Phone}
            placeholder="Your phone"
            autoComplete="off"
            onChange={(e) => {
              const value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
              if (value.length <= 10) {
                handleInputChange({ target: { name: "Phone", value } });
              }
            }}
            maxLength="10"
            style={{ appearance: "textfield", MozAppearance: "textfield", WebkitAppearance: "none" }}
          />

          {errors.Phone && <p className="error">Please enter a valid phone number (10 digits)</p>}
          <input
            type="text"
            name="College"
            value={formData.College}
            placeholder="Your college"
            autoComplete="off"
            onChange={handleInputChange}
          />
          {errors.College && <p className="error">Please enter your college</p>}
          <input
            type="text"
            name="Academic_year"
            value={formData.Academic_year}
            placeholder="Your academic year"
            autoComplete="off"
            onChange={handleInputChange}
          />
          {errors.Academic_year && <p className="error">Please enter your academic year</p>}
          <input
            type="text"
            name="Department"
            value={formData.Department}
            placeholder="Your department"
            autoComplete="off"
            onChange={handleInputChange}
          />
          {errors.Department && <p className="error">Please enter your department</p>}
          <button type="submit">{loading?<Loader/>:'Click here to start '}</button>
        </form>
      </div>
    </div>
  );
};

export default Home;
