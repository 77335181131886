import React from 'react';
import { useNavigate } from 'react-router-dom';

const Instructions = ({ setStartTimer, name }) => {
  const navigate = useNavigate();

  const startQuiz = () => {
    navigate('/quiz');
    setStartTimer(true);
  };
  return (
    <div className="Main_Div">
      <div className="Instructions_Div">
        <h4>
          Hi <span>{name}</span>, Welcome!! Please read the instructions
          carefully
        </h4>
        <ol>
          <li>
            This Test consists of 60 questions.
            <ol type="I">
              <li>Verbal ability questions </li>
              <li>AI related questions </li>
              <li>OOPS questions </li>
              <li>Python programming questions </li>
              <li>C programming questions </li>
              <li>Logical reasoning questions </li>

              {/* <li>Verbal ability questions (10)</li>
              <li>Ai and current affairs Questions (10)</li>
              <li>OOPS Questions (10)</li>
              <li>Python programming questions (10)</li>
              <li>C programming questions (10)</li>
              <li>Logical reasoning questions (10)</li> */}
            </ol>
          </li>
          <li>You will be given 60 minutes for the test.</li>
          <li>
            Give the answers carefully as you can't go back once you select an
            answer
          </li>
          <li>Each correct answer carries 1 mark</li>
          <li>To start the Test, click the “Start Test button.</li>
        </ol>
        <button onClick={startQuiz}>Start Test</button>
      </div>
    </div>
  );
};

export default Instructions;
